<template>
  <div class="first-reservation-input-page">
    <validation-observer v-slot="{ invalid }" class="observer">
      <div class="page-title">
        <base-page-title>{{
          $t('firstReservationInputPage.inputInformation')
        }}</base-page-title>
      </div>
      <div class="reservation-progress-bar">
        <progress-bar v-bind="progressBarData" />
      </div>
      <div class="reservation-input-form">
        <input-table-first
          ref="inputTableFirst"
          :owner="owner"
          :patients="patients"
          :reservation="reservation"
          :reservationPurpose="reservationPurpose"
          :isOverPatientLimit="isOverPatientLimit"
          :apiResultPostalCode="mixinApiResultPostalCode"
          :usedMailMessage="mixinUsedMailMessage"
          @add-patient="addPatient"
          @del-patient="delPatient"
          @input-postal-code="inputPostalCode"
          @auto-input-address="mixinInputAddress('owner')"
          @keyup="mixinKeyupIsOkEmail($event, owner.email)"
        />
      </div>
      <div class="next-button">
        <base-decision-button
          :disabled="invalid || isOverPatientLimit || mixinIsNgEmail"
          @validate="isValid"
          @click="pushToRegistrationConfirm"
          >{{ $t('common.buttonNext') }}</base-decision-button
        >
      </div>
      <div class="back-button">
        <base-cancel-button @click="backPage">{{
          $t('common.buttonReturn')
        }}</base-cancel-button>
      </div>
    </validation-observer>
    <announce-popup
      v-if="alertFlg"
      @close="alertFlg = false"
      :title="$t('common.error')"
      :buttons="buttons"
      :type="'failure'"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import BasePageTitle from '@/components/parts/atoms/BasePageTitle'
import ProgressBar from '@/components/parts/organisms/ProgressBar'
import InputTableFirst from '@/components/parts/organisms/InputTableFirst'
import BaseDecisionButton from '@/components/parts/atoms/BaseDecisionButton'
import BaseCancelButton from '@/components/parts/atoms/BaseCancelButton'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import ReloadConfirm from '@/components/mixins/ReloadConfirm'
import IsOkEmail from '@/components/mixins/IsOkEmail'
import AutoInputPrefecture from '@/components/mixins/AutoInputPrefecture'
import { cloneDeep } from 'lodash'
import { mapGetters } from 'vuex'
import { createProgressBarDataForReservation } from '@/utils/createProgressBarData'
import { usedMailMessage } from '@/utils/validation_rules'

export default {
  name: 'FirstReservationInputPage',

  components: {
    BasePageTitle,
    ProgressBar,
    InputTableFirst,
    BaseDecisionButton,
    BaseCancelButton,
    AnnouncePopup,
    ValidationObserver
  },

  mixins: [ReloadConfirm, IsOkEmail, AutoInputPrefecture],

  data() {
    return {
      progressBarData: {},
      owner: cloneDeep(this.$store.getters['owner/getNewOwner']),
      patients:
        this.$store.getters['patient/getNewPatients'].length > 0
          ? cloneDeep(this.$store.getters['patient/getNewPatients'])
          : cloneDeep([{ name: '', speciesId: '', breed: '', key: 1 }]),
      reservation: cloneDeep(
        this.$store.getters['reservation/getNewReservation']
      ),
      key: 1,
      alertFlg: false,
      popupMessage: '',
      buttons: [this.$t('common.close')]
    }
  },

  computed: {
    ...mapGetters({
      scenario: 'auth/scenario',
      getMasterDatum: 'master/getDataById'
    }),
    reservationPurpose() {
      return this.$store.getters[
        'reservationPurpose/getReservationPurposeById'
      ](this.reservation.reservationPurposeId)
    },
    isOverPatientLimit() {
      return this.patients.length > this.reservationPurpose.maxPatients
    }
  },

  watch: {
    owner: {
      handler: function() {
        this.$store.dispatch('owner/setNewOwner', this.owner)
      },
      deep: true
    },
    patients: {
      handler: function() {
        this.$store.dispatch('patient/setNewPatients', this.patients)
      },
      deep: true
    },
    reservation: {
      handler: function() {
        this.$store.dispatch('reservation/setNewReservation', this.reservation)
      },
      deep: true
    }
  },

  created() {
    this.progressBarData = createProgressBarDataForReservation(this.scenario, 4)
    this.key = this.patients.length
  },

  methods: {
    isValid(invalidFlg) {
      this.$store.dispatch('petorelu/invalidFlg', invalidFlg)
    },
    addPatient() {
      this.key += 1
      const patient = { name: '', speciesId: '', breed: '', key: this.key }
      this.patients.push(patient)
    },
    delPatient(obj) {
      this.patients.splice(obj.index, 1)
    },
    inputPostalCode(val) {
      if (
        !this.mixinApiResultPostalCode?.existingPostalCodeFlg &&
        val.length !== 7
      ) {
        this.mixinApiResultPostalCode = null
      }
      this.owner.postalCode = val
    },
    async pushToRegistrationConfirm() {
      const result = await this.mixinFetchIsOkEmail(this.owner.email)
      if (result?.uniqueEmail) {
        this.$router.push({ name: 'RegistrationConfirm' })
      } else if (result?.uniqueEmail === false) {
        this.mixinIsNgEmail = true
        this.mixinUsedMailMessage = usedMailMessage
        this.focusMailTextBox()
      } else {
        this.alertFlg = true
        this.popupMessage = this.$t('common.popupMessageServerErrorWait')
      }
    },
    focusMailTextBox() {
      // 下記の処理は$refsを二段階以上で使用していてモック化できなかったため、テストのため関数にしてモック化できるようにしました。
      this.$refs.inputTableFirst.$refs.baseWideTextBox.$refs.wideTextBox.focus()
    },
    backPage() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.first-reservation-input-page {
  > .observer {
    > .page-title {
      margin-top: 70px;
    }
    > .reservation-progress-bar {
      margin: 28px 0 54px;
    }
    > .next-button {
      margin-top: 60px;
    }
    > .back-button {
      margin: 23px 0 80px 0;
    }
  }
}
</style>
