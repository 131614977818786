export const createProgressBarDataForReservation = (
  scenario,
  textOrangeNumber
) => {
  const textList = [
    'parts.reservationPurpose',
    'parts.selectReservationDate',
    'parts.selectReservationTime',
    'parts.inputInformation',
    'parts.confirm'
  ]
  if (scenario === 'first_reservation' || scenario === '')
    textList.push('parts.mailConfirmation')
  textList.push('parts.finish')
  const barWidthPc =
    scenario === 'first_reservation' || scenario === ''
      ? 'new-user-reservation-screen'
      : 'login-user-reservation-screen'
  if (scenario === '') textOrangeNumber = 7
  return { textList, barWidthPc, textOrangeNumber }
}
