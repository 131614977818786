<template>
  <div class="base-multiple-lines-text-box">
    <textarea
      class="multiple-lines-text-box"
      :value="value"
      :placeholder="placeholder"
      @input="inputText"
      @blur="$emit('blur')"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseMultipleLinesTextBox',

  props: {
    value: { type: String },
    placeholder: { type: String }
  },

  methods: {
    inputText(e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-multiple-lines-text-box {
  > .multiple-lines-text-box {
    width: 600px;
    height: 250px;
    border-radius: 5px;
    border: solid 2px #{$plus_ce_gray};
    background-color: #{$plus_white};
    padding: 9px 15px;
    @include font-size(18);
    line-height: 1.5;
    text-align: left;
    box-sizing: border-box;
    resize: none;
    &::placeholder {
      color: #{$plus_be_gray};
    }
  }
}

@media (max-width: $global-media-width) {
  .base-multiple-lines-text-box {
    > .multiple-lines-text-box {
      width: 100%;
    }
  }
}
</style>
