<template>
  <div class="input-table-first">
    <table>
      <tr>
        <th>{{ $t('common.labelPurposeOfVisit') }}</th>
        <td data-test="reservation-purpose">
          <span class="text">{{
            this.displayLanguage === 'en'
              ? reservationPurpose.nameEnglish
              : reservationPurpose.name
          }}</span>
        </td>
      </tr>
      <tr>
        <th>{{ $t('common.labelDateRequested') }}</th>
        <td data-test="reservation-date">
          {{
            reservation.date
              | dateFormatter(reservation.startTime, this.displayLanguage)
          }}
        </td>
      </tr>
      <tr>
        <th>
          {{ $t('common.labelName')
          }}{{ $t('common.labelJaBrackets', { str: '全角' }) }}
          <base-required-mark />
        </th>
        <td>
          <full-name-input-form :owner="owner" />
        </td>
      </tr>
      <tr>
        <th>
          {{ $t('common.labelNameKana')
          }}{{ $t('common.labelJaBrackets', { str: 'カナ' }) }}
          <base-required-mark />
        </th>
        <td>
          <full-name-kana-input-form :owner="owner" />
        </td>
      </tr>
      <tr>
        <th class="patient-th">
          <span class="label">{{ $t('common.labelPetName') }}</span
          ><base-required-mark /><br />
          <span class="note" data-test="max-patients">{{
            $t('parts.maximumPatient', {
              patientNumber: reservationPurpose.maxPatients
            })
          }}</span
          ><br />
          <span
            class="error-msg"
            v-if="isOverPatientLimit"
            data-test="patient-num-err-msg"
          >
            {{ $t('parts.errorPatientLimit') }}
          </span>
        </th>
        <td class="patient-td">
          <patient-data-input-form
            v-for="(patient, i) in patients"
            :key="patient.key"
            :patient="patient"
            :index="i"
            @del-patient="delPatient"
          />
          <base-button-plus
            class="add-patient-button"
            :text="$t('common.buttonAddPatient')"
            :disabledFlg="!patientAddableFlg"
            @click="addPatient"
          />
        </td>
      </tr>
      <tr>
        <th>
          {{ $t('common.labelPhoneNumber')
          }}{{ $t('common.labelJaBrackets', { str: '半角数字' }) }}
          <base-required-mark />
        </th>
        <td>
          <validation-provider
            rules="requiredRule|regexTel"
            v-slot="{ errors }"
          >
            <base-wide-text-box
              :type="'tel'"
              :placeholder="'08012345678'"
              :maxlength="11"
              v-model="owner.tel"
            />
            <div class="error" data-test="tel-err">{{ $t(errors[0]) }}</div>
          </validation-provider>
        </td>
      </tr>
      <tr>
        <th>{{ $t('common.labelEmail') }} <base-required-mark /></th>
        <td>
          <validation-provider
            rules="requiredRule|emailRule"
            v-slot="{ errors }"
          >
            <base-wide-text-box
              ref="baseWideTextBox"
              :name="'email'"
              :type="'email'"
              :placeholder="'test@mail.com'"
              :maxlength="254"
              v-model="owner.email"
              @keyup="$emit('keyup', errors)"
            />
            <div class="error" data-test="email-err">
              {{ errors[0] ? $t(errors[0]) : $t(usedMailMessage) }}
            </div>
          </validation-provider>
        </td>
      </tr>
      <tr>
        <th>{{ $t('common.labelPassword') }} <base-required-mark /></th>
        <td>
          <validation-provider
            rules="requiredRule|regexHalfWidth|minPassRule:8"
            v-slot="{ errors }"
          >
            <base-wide-text-box
              :type="'password'"
              :placeholder="$t('common.placeholderPassword')"
              :maxlength="100"
              v-model="owner.password"
            />
            <div class="error" data-test="pass-err">{{ $t(errors[0]) }}</div>
          </validation-provider>
        </td>
      </tr>
      <tr>
        <th>{{ $t('common.labelPasswordReEnter') }} <base-required-mark /></th>
        <td>
          <validation-provider
            :rules="{
              requiredRule: true,
              regexHalfWidth: true,
              minPassRule: 8,
              isRePassRule: owner.password
            }"
            :immediate="owner.reEnterPassword.length > 0"
            v-slot="{ errors }"
          >
            <base-wide-text-box
              :type="'password'"
              :placeholder="$t('common.placeholderPassword')"
              :maxlength="100"
              v-model="owner.reEnterPassword"
            />
            <div class="error" data-test="repass-err">{{ $t(errors[0]) }}</div>
          </validation-provider>
        </td>
      </tr>
      <tr>
        <th>{{ $t('common.labelPostalCode') }}</th>
        <td>
          <auto-input-form
            :validationRules="{
              regexPostalCode: true,
              apiResultPostalCode: apiResultPostalCode
            }"
            :immediate="true"
            :placeholder="$t('common.placeholderPostalCode')"
            :maxlength="7"
            :inputStyle="{ width: '120px' }"
            :buttonText="$t('common.buttonAutoFillAddress')"
            :value="owner.postalCode"
            @input="$emit('input-postal-code', $event)"
            @click="$emit('auto-input-address')"
          />
        </td>
      </tr>
      <tr>
        <th>{{ $t('common.labelPrefecture') }}</th>
        <td class="prefectures">
          <base-select-box
            :selectData="prefecturesSelect"
            v-model.number="owner.prefectureId"
          />
        </td>
      </tr>
      <tr>
        <th>{{ $t('common.labelCityTownVillage') }}</th>
        <td>
          <validation-provider
            :rules="{ notSurrogatePair: true }"
            v-slot="{ errors }"
          >
            <base-wide-text-box
              :placeholder="
                $t('common.placeholderStringLength', { length: '50' })
              "
              :maxlength="50"
              v-model="owner.address"
            />
            <div class="error">{{ $t(errors[0]) }}</div>
          </validation-provider>
        </td>
      </tr>
      <tr>
        <th>{{ $t('common.labelBuildingApartment') }}</th>
        <td>
          <validation-provider
            :rules="{ notSurrogatePair: true }"
            v-slot="{ errors }"
          >
            <base-wide-text-box
              :placeholder="
                $t('common.placeholderStringLength', { length: '50' })
              "
              :maxlength="50"
              v-model="owner.building"
            />
            <div class="error">{{ $t(errors[0]) }}</div>
          </validation-provider>
        </td>
      </tr>
      <tr>
        <th class="memo-th">
          {{ $t('common.labelRemarks') }}
          <base-required-mark v-if="isMemoRequired" />
          <div class="memo-notice" data-test="memo-notice">
            {{ memoNotice[this.displayLanguage] }}
          </div>
        </th>
        <td>
          <validation-provider
            :rules="{ notSurrogatePair: true, requiredRule: isMemoRequired }"
            v-slot="{ errors }"
          >
            <base-multiple-lines-text-box
              v-model="reservation.memo"
              data-test="memo"
            />
            <div class="error" data-test="memo-err">{{ $t(errors[0]) }}</div>
          </validation-provider>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import moment from 'moment'
import { ValidationProvider } from 'vee-validate'
import BaseRequiredMark from '@/components/parts/atoms/BaseRequiredMark.vue'
import FullNameInputForm from '@/components/parts/molecules/FullNameInputForm.vue'
import FullNameKanaInputForm from '@/components/parts/molecules/FullNameKanaInputForm.vue'
import PatientDataInputForm from '@/components/parts/molecules/PatientDataInputForm.vue'
import BaseButtonPlus from '@/components/parts/atoms/BaseButtonPlus.vue'
import BaseWideTextBox from '@/components/parts/atoms/BaseWideTextBox.vue'
import BaseMultipleLinesTextBox from '@/components/parts/atoms/BaseMultipleLinesTextBox.vue'
import AutoInputForm from '@/components/parts/molecules/AutoInputForm'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox.vue'
import '@/utils/validation_rules'
import { mapGetters } from 'vuex'

moment.updateLocale('en', {
  longDateFormat: {
    LLLL: 'dddd, MMMM D, YYYY, [from] h:mm a to'
  }
})
moment.updateLocale('ja', {
  longDateFormat: {
    LLLL: 'YYYY年MM月DD日（dd）HH時mm分～'
  }
})

export default {
  name: 'InputTableFirst',

  components: {
    ValidationProvider,
    BaseRequiredMark,
    FullNameInputForm,
    FullNameKanaInputForm,
    PatientDataInputForm,
    BaseButtonPlus,
    BaseWideTextBox,
    BaseMultipleLinesTextBox,
    AutoInputForm,
    BaseSelectBox
  },

  filters: {
    dateFormatter: (day, time, locale) => {
      const date = day + 'T' + time
      moment.locale(locale)
      return moment(date).format('LLLL')
    }
  },

  props: {
    owner: {
      firstName: { type: String },
      lastName: { type: String },
      firstNameKana: { type: String },
      lastNameKana: { type: String },
      tel: { type: String },
      email: { type: String },
      password: { type: String },
      postalCode: { type: String },
      prefectureId: { type: Number },
      address: { type: String },
      building: { type: String },
      reEnterPassword: { type: String }
    },
    patients: { type: Array },
    reservation: {
      reservationPurposeId: { type: Number },
      date: { type: String },
      startTime: { type: String },
      memo: { type: String }
    },
    reservationPurpose: { type: Object },
    isOverPatientLimit: { type: Boolean },
    apiResultPostalCode: { type: Object, default: () => null },
    usedMailMessage: { type: String }
  },

  computed: {
    ...mapGetters({
      prefecturesZero: 'master/selectPrefecturesZero'
    }),
    patientAddableFlg() {
      return this.reservationPurpose.maxPatients > this.patients.length
    },
    memoNotice() {
      const reservationSetting = this.$store.getters[
        'reservationSetting/getReservationSetting'
      ]
      return {
        en: reservationSetting.memoNoticeEnglish,
        ja: reservationSetting.memoNotice
      }
    },
    isMemoRequired() {
      return (
        this.$store.getters['reservationSetting/getReservationSetting']
          .memoRequiredFlg === 1
      )
    },
    prefecturesSelect() {
      return this.displayLanguage === 'en'
        ? this.prefecturesZero.map(v => {
            return { ...v, name: v.nameEnglish || v.name }
          })
        : this.prefecturesZero
    },
    displayLanguage() {
      return this.$i18n.locale
    }
  },

  methods: {
    addPatient() {
      this.$emit('add-patient')
    },
    delPatient(obj) {
      this.$emit('del-patient', obj)
    }
  }
}
</script>

<style lang="scss" scoped>
.input-table-first {
  width: 100%;
  margin: 0 auto;
  > table {
    margin: 0 auto;
    width: 960px;
    height: 27px;
    @include font-size(18);
    font-weight: bold;
    line-height: 1.5;
    text-align: left;
    color: $plus_black;
    border-spacing: 0px 25px;
    > tr {
      > th {
        width: 40%;
        > .note {
          text-decoration: underline;
          text-decoration-color: #{$plus_red};
        }
        > .error-msg {
          color: #{$plus_red};
        }
        &.patient-th {
          vertical-align: top;
          > .label {
            line-height: 45px;
            margin-right: 10px;
          }
        }
        &.memo-th {
          vertical-align: top;
          > .memo-notice {
            white-space: pre-line;
            overflow-wrap: break-word;
            font-weight: normal;
            font-size: 13px;
            word-break: break-all;
          }
        }
      }
      > th.patient-th,
      td.patient-td {
        padding-top: 10px;
      }
      > td {
        width: 60%;
        @include validate-message();
        &[data-test='reservation-purpose'] {
          width: 100%;
          display: flex;
          > .text {
            width: 0;
            flex: 1;
            overflow-wrap: break-word;
          }
        }
      }
    }
  }
}
.err-msg {
  color: red;
  font-size: 12px;
}

@media (max-width: $global-media-width) {
  .input-table-first {
    width: 70%;
    margin: 0 auto;
    > table {
      width: 95%;
      > tr {
        > th,
        td {
          width: 100%;
          display: block;
          margin-bottom: 15px;
          &.patient-th {
            line-height: 1.5;
          }
        }
        > th.patient-th,
        td.patient-td {
          padding-top: 0px;
          > .label {
            line-height: 1.5;
          }
        }
      }
    }
  }
}
@media (max-width: $global-container) {
  .input-table-first {
    margin: 0 auto;
  }
}
@media (min-width: 960px) {
  .memo-notice {
    width: 90%;
  }
}
</style>
